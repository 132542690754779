import React, { useState } from 'react';
import LandingPage from './Component/LandingPage';
import Dashboard from './Pages/Dashboard';
import Home from './Pages/Home';
import SignupForm from './Pages/SignUpForm';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/SignupForm" element={<SignupForm />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
