import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import dayjs from 'dayjs';

const activities = [
    { name: 'Jim', date: '2023-09-01', activity: 'Started Training' },
    { name: 'Abigail', date: '2023-09-02', activity: 'Added Documents' },
    { name: 'Morgan James', date: '2023-09-05', activity: 'Queried' },
    { name: 'Yu Hao', date: '2023-09-06', activity: 'Created Ticket' },
    { name: 'John', date: '2024-04-08', activity: 'Started Training' },
    { name: 'Emma', date: '2024-04-09', activity: 'Added Documents' },
    { name: 'Ava', date: '2024-04-10', activity: 'Queried' },
    { name: 'Sophia', date: '2024-04-11', activity: 'Created Ticket' },
    { name: 'Isabella', date: '2024-04-12', activity: 'Answered Ticket' },
    { name: 'Mia', date: '2024-04-13', activity: 'Completed Task' },
    { name: 'Amelia', date: '2024-04-14', activity: 'Added Task' },
    { name: 'Harper', date: '2024-04-08', activity: 'Started Training' },
    { name: 'Evelyn', date: '2024-04-09', activity: 'Added Documents' },
    { name: 'Abigail', date: '2024-04-10', activity: 'Queried' },
    { name: 'Emily', date: '2024-04-11', activity: 'Created Ticket' },
    { name: 'Elizabeth', date: '2024-04-12', activity: 'Answered Ticket' },
    { name: 'Mila', date: '2024-04-13', activity: 'Completed Task' },
    { name: 'Ella', date: '2024-04-14', activity: 'Added Task' },
    { name: 'Avery', date: '2024-04-08', activity: 'Started Training' },
    { name: 'Sofia', date: '2024-04-09', activity: 'Added Documents' },
    { name: 'Camila', date: '2024-04-10', activity: 'Queried' },
    { name: 'Aria', date: '2024-04-11', activity: 'Created Ticket' },
    { name: 'Scarlett', date: '2024-04-12', activity: 'Answered Ticket' },
    { name: 'Victoria', date: '2024-04-13', activity: 'Completed Task' },
    { name: 'Madison', date: '2024-04-14', activity: 'Added Task' },
    { name: 'Luna', date: '2024-04-08', activity: 'Started Training' },
    { name: 'Grace', date: '2024-04-09', activity: 'Added Documents' },
    { name: 'Chloe', date: '2024-04-10', activity: 'Queried' },
    { name: 'Penelope', date: '2024-04-11', activity: 'Created Ticket' },
    { name: 'Layla', date: '2024-04-12', activity: 'Answered Ticket' },
    { name: 'Riley', date: '2024-04-13', activity: 'Completed Task' },
    { name: 'Zoey', date: '2024-04-14', activity: 'Added Task' },
    { name: 'Nora', date: '2024-04-08', activity: 'Started Training' },
    { name: 'Lily', date: '2024-04-09', activity: 'Added Documents' },
    { name: 'Eleanor', date: '2024-04-10', activity: 'Queried' },
    { name: 'Hannah', date: '2024-04-11', activity: 'Created Ticket' },
    { name: 'Lillian', date: '2024-04-12', activity: 'Answered Ticket' },
    { name: 'Addison', date: '2024-04-13', activity: 'Completed Task' },
    { name: 'Aubrey', date: '2024-04-14', activity: 'Added Task' },
  ];
  

const ActivityComponent = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [filter, setFilter] = useState('all');

  const activitiesPerPage = 10;
  const pagesVisited = pageNumber * activitiesPerPage;

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  const filterActivities = () => {
    const now = dayjs();
    return activities.filter(activity => {
      if (filter === '7days') {
        return dayjs(activity.date).isAfter(now.subtract(7, 'days'));
      } else if (filter === '24hr') {
        return dayjs(activity.date).isAfter(now.subtract(1, 'day'));
      } else if (filter === '1month') {
        return dayjs(activity.date).isAfter(now.subtract(1, 'month'));
      } else {
        return true;
      }
    });
  };

  const displayActivities = filterActivities().slice(
    pagesVisited,
    pagesVisited + activitiesPerPage
  );

  return (
    <div className="bg-white/30 backdrop-blur-md p-6 mt-8 rounded-3xl shadow-lg w-full max-w-7xl">
      <h2 className="text-xl font-semibold mb-4">Activity</h2>
      <div className="mb-4">
        <select
          className="p-2 rounded-xl"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="all">All Time</option>
          <option value="7days">Last 7 Days</option>
          <option value="24hr">Last 24 Hours</option>
          <option value="1month">Last 1 Month</option>
        </select>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-transparent">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-300">Name</th>
              <th className="py-2 px-4 border-b border-gray-300">Date</th>
              <th className="py-2 px-4 border-b border-gray-300">Activity</th>
            </tr>
          </thead>
          <tbody>
            {displayActivities.map((activity, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b border-gray-300">{activity.name}</td>
                <td className="py-2 px-4 border-b border-gray-300">{activity.date}</td>
                <td className="py-2 px-4 border-b border-gray-300">{activity.activity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        pageCount={Math.ceil(filterActivities().length / activitiesPerPage)}
        onPageChange={handlePageClick}
        containerClassName={'flex justify-center mt-4'}
        pageClassName={'mx-2'}
        previousClassName={'mx-2'}
        nextClassName={'mx-2'}
        activeClassName={'text-blue-500'}
      />
    </div>
  );
};

export default ActivityComponent;
