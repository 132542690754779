import React, { useState, useEffect } from 'react';
import { Download, Upload, Eye, Trash,FileSliders } from 'lucide-react';
import Progress from './Progress'; // Import the Progress component

function FileTable() {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [training, setTraining] = useState(false); // State to track training

  const ALLOWED_FILE_TYPES = [
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/pdf', // .pdf
    'text/plain', // .txt
  ];

  async function fetchFiles() {
    const response = await fetch('https://leap.up.railway.app/files');
    if (response.ok) {
      const files = await response.json();
      console.log(files)
      setFiles(files)
    } else {
      console.error('Failed to list files', await response.json());
    }
  }

  const formatFileSize = (bytes) => {
    const KB = 1024;
    const MB = KB * KB;
  
    if (bytes < KB) {
      return `${bytes} B`;
    } else if (bytes < 1000 * KB) {
      return `${(bytes / KB).toFixed(2)} KB`;
    } else {
      return `${(bytes / MB).toFixed(2)} MB`;
    }
  }

  function formatDate(isoString) {
    const date = new Date(isoString);
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

  useEffect(() => {
    fetchFiles();
  }, []);

  async function handleFileUpload(event) {
    const file = event.target.files[0];
  
    if (ALLOWED_FILE_TYPES.includes(file.type)) {
      setError(''); // Clear previous error
      const formData = new FormData();
      formData.append('file', file);
  
      const response = await fetch('https://leap.up.railway.app/upload', {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        await fetchFiles(); // Refresh the list after upload
      } else if (response.status === 409) {
        setError('File already exists');
      } else {
        setError('File upload failed');
      }
    } else {
      setError('Invalid format. Only Word documents, PDFs, and text files are allowed.');
    }
  }
  

  async function handleFileDownload(fileName) {
    await downloadFile(fileName);
  }

  async function handleFileDelete(fileName) {
    await deleteFile(fileName);
    await fetchFiles(); // Refresh the list after deletion
  }

  async function handleTrain() {
    // Trigger training logic here
    setTraining(true); // Show progress component
    const response = await fetch('https://leap.up.railway.app/train', { method: 'DELETE' });
    if (response.ok) {
      console.log('Training initiated successfully');
    } else {
      console.error('Training initiation failed', await response.json());
    }
    setTraining(false); // Hide progress component
  }


  return (
    <section className="container px-4 mx-auto">
      <div className="sm:flex sm:items-center sm:justify-between">
        <h2 className="text-lg font-medium text-gray-800 dark:text-white">Files uploaded</h2>
        <div className="flex items-center mt-4 gap-x-3">
        <button onClick={handleTrain} className="flex items-center justify-center w-1/2 px-5 py-2 text-md tracking-wide text-white transition-colors duration-200 bg-green-500 rounded-xl sm:w-auto gap-x-2 hover:bg-green-600 dark:hover:bg-green-500 dark:bg-green-600">
        <FileSliders />
            <span>Train</span>
          </button>
          <input type="file" onChange={handleFileUpload} className="hidden" id="upload-input" />
          <label htmlFor="upload-input" className="flex items-center justify-center w-1/2 px-5 py-2 text-md tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-xl sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">
            <Upload />
            <span>Upload</span>
          </label>
        </div>
      </div>
      {error && <div className="text-red-500 mt-4">{error}</div>}
      {training && <Progress />}
      {/* File listing */}
      <div className="flex flex-col mt-6">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-2xl">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-50 dark:bg-gray-800">
                  <tr>
                    <th className="px-4 py-3 text-sm font-normal text-left text-gray-500 dark:text-gray-400">File name</th>
                    <th className="px-4 py-3 text-sm font-normal text-left text-gray-500 dark:text-gray-400">File Size</th>
                    <th className="px-4 py-3 text-sm font-normal text-left text-gray-500 dark:text-gray-400">File Added</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                  {files.map(file => (
                    <tr key={file.name}>
                      <td className="px-4 py-4 text-sm font-medium text-gray-700">{file.name}</td>
                      <td className="px-4 py-4 text-sm font-medium text-gray-700">{formatFileSize(file.size)}</td>
                      <td className="px-4 py-4 text-sm font-medium text-gray-700">{formatDate(file.dateAdded)}</td>
                      <td className="px-4 py-4 text-sm font-medium text-gray-700">
                        <button onClick={() => handleFileDownload(file.name)} className="px-2 py-1 text-gray-500 hover:bg-gray-100">
                          <Download />
                        </button>
                        <button onClick={() => handleFileDelete(file.name)} className="px-2 py-1 text-gray-500 hover:bg-gray-100">
                          <Trash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

async function downloadFile(fileName) {
  const response = await fetch(`http://localhost:3001/download/${fileName}`);

  if (response.ok) {
    const blob = await response.blob(); // Fetch as a blob
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName; // Set the filename for download
    document.body.appendChild(a);
    a.click();
    a.remove();
  } else {
    console.error('Failed to download file', await response.json());
  }
}


async function deleteFile(fileName) {
  const response = await fetch(`http://localhost:3001/delete/${fileName}`, {
    method: 'DELETE',
  });

  if (response.ok) {
    console.log('File deleted successfully');
  } else {
    console.error('File deletion failed', await response.json());
  }
}


export default FileTable;
