import React from 'react';
import { CheckCircle, ClipboardList, Brain, MessageCircle, Lock, Database } from 'lucide-react';

export default function LeapCrewAdvantage() {
  return (
    <section className="w-full pt-12 md:pt-24 lg:pt-32 xl:pt-48 bg-white">
      <div className="container px-4 md:px-6">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none text-black">
            The LeapCrew Advantage
          </h1>
          <p className="max-w-[600px] text-gray-800 md:text-xl mx-auto">
            Simplify and streamline your HR processes with our AI-powered solutions.
          </p>
        </div>
        <div className="grid gap-6 items-center">
          <div className="w-full max-w-full space-y-4 mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
              <div className="flex flex-col items-center space-y-2 border-gray-300 p-4 rounded-2xl shadow-md">
                <div className="p-2 bg-gray-100 rounded-full">
                  <CheckCircle className="text-black h-8 w-8 mb-2" />
                </div>
                <h2 className="text-xl font-bold text-black">Instant Answers from Documentation</h2>
                <p className="text-gray-700">
                  Quickly access the information you need from your documentation, ensuring employees find the answers they seek with ease.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 border-gray-300 p-4 rounded-2xl shadow-md">
                <div className="p-2 bg-gray-100 rounded-full">
                  <ClipboardList className="text-black h-8 w-8 mb-2" />
                </div>
                <h2 className="text-xl font-bold text-black">Smart Ticketing for Unanswered Questions</h2>
                <p className="text-gray-700">
                  Automatically generate support tickets for complex queries, allowing you to focus on more challenging tasks.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 border-gray-300 p-4 rounded-2xl shadow-md">
                <div className="p-2 bg-gray-100 rounded-full">
                  <Brain className="text-black h-8 w-8 mb-2" />
                </div>
                <h2 className="text-xl font-bold text-black">Continuous Learning</h2>
                <p className="text-gray-700">
                  Our AI learns from your responses to improve over time, reducing repetitive questions and saving you time.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 border-gray-300 p-4 rounded-2xl shadow-md">
                <div className="p-2 bg-gray-100 rounded-full">
                  <MessageCircle className="text-black h-8 w-8 mb-2" />
                </div>
                <h2 className="text-xl font-bold text-black">Understand Employee Sentiment and Needs</h2>
                <p className="text-gray-700">
                  Gain valuable insights into employee queries to better understand and address their needs and concerns.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 border-gray-300 p-4 rounded-2xl shadow-md">
                <div className="p-2 bg-gray-100 rounded-full">
                  <Lock className="text-black h-8 w-8 mb-2" />
                </div>
                <h2 className="text-xl font-bold text-black">Private Data Hosting</h2>
                <p className="text-gray-700">
                  We ensure your data remains private and secure, using dedicated databases and servers for each company.
                </p>
              </div>
              <div className="flex flex-col items-center space-y-2 border-gray-300 p-4 rounded-2xl shadow-md">
                <div className="p-2 bg-gray-100 rounded-full">
                  <Database className="text-black h-8 w-8 mb-2" />
                </div>
                <h2 className="text-xl font-bold text-black">Customizable Solutions</h2>
                <p className="text-gray-700">
                  Customize your setup with your own vector database, bucket, API key for AI inference, and Supabase key, without sharing information with us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
