import React, { useState } from 'react';
import axios from 'axios';
import { useUser } from '@clerk/clerk-react';

const SentimentComponent = ({ onFeedbackReceived }) => {
  const [rating, setRating] = useState(null);
  const [feedbackReceived, setFeedbackReceived] = useState(false);
  const { user } = useUser();

  const handleRating = async (rate) => {
    setRating(rate);
    try {
      await axios.post('https://leap.up.railway.app/sentiment', {
        userId: user.id,
        rating: rate,
      });
      setFeedbackReceived(true);
      setTimeout(() => {
        setFeedbackReceived(false);
        onFeedbackReceived();
      }, 2000); // Remove component after 2 seconds
    } catch (error) {
      console.error('Error submitting rating:', error);
      alert('Failed to submit rating');
    }
  };

  if (feedbackReceived) {
    return (
      <div className="bg-white bg-opacity-20 backdrop-blur-lg shadow-lg rounded-2xl p-4 mb-4 absolute top-4 right-4 flex items-center justify-center">
        <h2 className="text-lg font-medium text-gray-800 dark:text-gray-200">Feedback received</h2>
      </div>
    );
  }

  return (
    <div className="bg-white bg-opacity-20 backdrop-blur-lg shadow-lg rounded-2xl p-4 mb-4 absolute top-4 right-4">
      <h2 className="text-lg font-medium text-gray-800 dark:text-gray-200">Rate your experience</h2>
      <div className="flex space-x-2 mt-2">
        {[1, 2, 3, 4, 5].map((rate) => (
          <button
            key={rate}
            className={`w-10 h-10 rounded-full ${rating === rate ? 'bg-blue-500 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200'}`}
            onClick={() => handleRating(rate)}
          >
            {rate}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SentimentComponent;
