import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Send } from 'lucide-react';
import { Link, animateScroll as scroll } from 'react-scroll';
import FaqHome from './Faq';
import Feature from './Feature';
import Pricing from './Pricing';
import Navbar from './Navbar';
import Logo from '../LogoZoom.png';

const LandingPage = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const navigate = useNavigate();
  const chatContainerRef = useRef(null);

  const hrChatMessages = [
    { sender: 'bot', text: "Hi Aditya, How can I help you?" },
    { sender: 'user', text: "How many days of annual leave am I entitled to?" },
    { sender: 'bot', text: "You are typically entitled to 20 days of annual leave per year" },
    { sender: 'user', text: "If I take some annual leave within my probation period, will that extend my probation period?" },
    { sender: 'bot', text: "Hey there! I'm actually not sure about this question. Let me raise a ticket to ask someone in HR and get back to you on that one." },
    { sender: 'user', text: "Thank you :)" },
  ];

  const reimbursementChatMessages = [
    { sender: 'bot', text: "Hi Jared, How can I assist you with reimbursements today?" },
    { sender: 'user', text: "How do I submit a reimbursement request?" },
    { sender: 'bot', text: "To submit a reimbursement request, please fill out the reimbursement form on our company portal and attach the necessary receipts." },
    { sender: 'user', text: "What kind of expenses are eligible for reimbursement?" },
    { sender: 'bot', text: "Eligible expenses include travel, accommodation, meals during business trips, and office supplies. Please refer to the company policy for more details." },
    { sender: 'user', text: "How long does it take to process a reimbursement request?" },
    { sender: 'bot', text: "Reimbursement requests are typically processed within 10 business days after submission." },
    { sender: 'user', text: "Thank you for the information!" },
    { sender: 'bot', text: "You're welcome! If you have any other questions, feel free to ask." },
  ];

  const itChatMessages = [
    { sender: 'bot', text: "Hi Kazi, How can the IT department assist you today?" },
    { sender: 'user', text: "I'm having trouble accessing the VPN. What should I do?" },
    { sender: 'bot', text: "Please ensure you are using the correct credentials and that your internet connection is stable." },
    { sender: 'user', text: "I've checked both, but it's still not working." },
    { sender: 'bot', text: "In that case, try restarting your computer. If the problem persists, I'll raise a ticket for further assistance." },
    { sender: 'user', text: "Okay, I'll try that. Thank you!" },
  ];

  const salesChatMessages = [
    { sender: 'bot', text: "Hi Emily, how can our AI assistant help you with your HR query today?" },
    { sender: 'user', text: "Can you provide me with the latest sales commission structure?" },
    { sender: 'bot', text: "Sure, here is the latest sales commission structure: \n\n- Sales up to $10,000: 5% commission \n- Sales between $10,001 and $50,000: 7% commission \n- Sales above $50,000: 10% commission \n- Bonus for exceeding quarterly targets: 3% additional commission." },
    { sender: 'user', text: "Great, thank you for the information!" },
    { sender: 'bot', text: "You're welcome! If you need anything else or have further questions, just let me know. If it's something outside my context, I can create a ticket for you." },
  ];

  const allChats = [hrChatMessages, reimbursementChatMessages, itChatMessages, salesChatMessages];

  const showNextChat = (availableChats, shownChats) => {
    if (availableChats.length === 0) {
      shownChats = [];
      availableChats = allChats;
    }

    const randomIndex = Math.floor(Math.random() * availableChats.length);
    const selectedChat = availableChats[randomIndex];
    const selectedIndex = allChats.indexOf(selectedChat);

    selectedChat.forEach((message, index) => {
      setTimeout(() => {
        setChatHistory(prevHistory => [...prevHistory, message]);
      }, index * 2000);
    });

    setTimeout(() => {
      setIsTransitioning(true);
      setChatHistory([]);
    }, selectedChat.length * 2000 + 3000); // 3 seconds after the last message

    setTimeout(() => {
      setIsTransitioning(false);
      shownChats.push(selectedIndex);
      sessionStorage.setItem('shownChats', JSON.stringify(shownChats));
      showNextChat(allChats.filter((_, index) => !shownChats.includes(index)), shownChats);
    }, selectedChat.length * 2000 + 4000); // 3 seconds delay before starting the next chat
  };

  useEffect(() => {
    if (showChat) {
      let shownChats = JSON.parse(sessionStorage.getItem('shownChats')) || [];
      let availableChats = allChats.filter((_, index) => !shownChats.includes(index));
      showNextChat(availableChats, shownChats);
    }
  }, [showChat]);

  useEffect(() => {
    setShowChat(true); // Automatically show chat on page load for demo purposes
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  return (
    <div className="bg-white min-h-screen flex flex-col font-sans text-black">
      <Navbar />
      <div className="flex-grow flex flex-col lg:flex-row items-center justify-between px-4 sm:px-6 lg:px-8 pt-20" style={{ minHeight: '80vh' }}>
        <header className="flex flex-col justify-center text-center lg:text-left lg:w-1/2 mb-8 lg:mb-0">
          <h1 className="text-5xl font-bold leading-tight mb-4">
          Automate Employee Queries for Your HR Team
          </h1>
          <p className="max-w-2xl text-lg mb-6">
          Instant Answers. Our Chatbot Learns from Your Documentation and Responses to Save You Time.
          </p>
          <div className="flex justify-center lg:justify-start">
          <button
              className="rounded-2xl border-2 border-dashed border-black bg-white px-6 py-3 font-semibold uppercase text-black text-base transition-all duration-300 hover:translate-x-[-4px] hover:translate-y-[-4px] hover:rounded-3xl hover:shadow-[4px_4px_0px_black] active:translate-x-[0px] active:translate-y-[0px] active:rounded-xl active:shadow-none"
              onClick={() => navigate('/SignupForm')}
            >
              Request a Demo
            </button>
          </div>
        </header>
        <div className="lg:w-1/2 flex flex-col justify-between p-8 bg-white rounded-xl shadow-lg border border-black/10 mt-8">
          <div
            ref={chatContainerRef}
            className="chat-container flex flex-col space-y-4 h-[550px] overflow-hidden"
          >
            {chatHistory.map((message, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
              >
                <div className={`p-4 mb-4 rounded-xl shadow-md max-w-xs border-l-[3px] border-b-[2px] border-black ${message.sender === 'user' ? 'bg-white text-black border-l-4' : 'bg-white text-black border-l-4'}`} style={{ whiteSpace: 'pre-wrap' }}>
                  <p>{message.text}</p>
                </div>
              </motion.div>
            ))}
          </div>
          <div className="mt-4 flex items-center">
            <input
              type="text"
              placeholder="Type your message..."
              className="flex-grow p-4 rounded-l-xl shadow-md border border-black focus:outline-none focus:ring-2 focus:ring-black"
            />
            <button className="p-4 bg-black text-white rounded-r-xl shadow-md flex items-center">
              <Send className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
      <div id="features" className="">
        <Feature />
      </div>
      <div id="pricing" className="">
        <Pricing />
      </div>
      <div id="faq" className="">
        <FaqHome />
      </div>
      <footer className="bg-white py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row justify-between items-center">
          <p className="text-black text-sm mb-4 lg:mb-0">© 2024 LeapCrew AI. All rights reserved.</p>
          <div className="flex space-x-4">
            <a className="text-black hover:text-black" href="#">Privacy Policy</a>
            <a className="text-black hover:text-black" href="#">Terms of Service</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;