import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import Logo from '../LogoZoom.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white bg-opacity-60 backdrop-blur-md py-4 border-b-2 border-black/10 fixed w-full top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between">
        <a className="text-black text-2xl font-semibold flex items-center" href="/">
          <img src={Logo} alt="Logo" className="h-12 w-12 rounded-full mr-2" />
          LeapCrew AI
        </a>
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="text-black focus:outline-none">
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
            </svg>
          </button>
        </div>
        <div className={`lg:flex ${isOpen ? 'block' : 'hidden'} space-y-4 lg:space-y-0 lg:space-x-4 border border-black/10 rounded-3xl px-8 py-2 lg:py-2 lg:px-8`}>
          <Link
            to="features"
            smooth={true}
            duration={500}
            className="block text-black hover:text-black px-3 py-2 rounded-md text-m font-medium cursor-pointer text-center"
          >
            Features
          </Link>
          <Link
            to="pricing"
            smooth={true}
            duration={500}
            className="block text-black hover:text-black px-3 py-2 rounded-md text-m font-medium cursor-pointer text-center"
          >
            Pricing
          </Link>
          <Link
            to="faq"
            smooth={true}
            duration={500}
            className="block text-black hover:text-black px-3 py-2 rounded-md text-m font-medium cursor-pointer text-center"
          >
            FAQ
          </Link>
        </div>
        <div className="hidden lg:block">
          <button
            className="rounded-2xl border-2 border-dashed border-black bg-white px-6 py-3 font-semibold uppercase text-black text-base transition-all duration-300 hover:translate-x-[-4px] hover:translate-y-[-4px] hover:rounded-3xl hover:shadow-[4px_4px_0px_black] active:translate-x-[0px] active:translate-y-[0px] active:rounded-xl active:shadow-none"
            onClick={() => navigate('/Dashboard')}
          >
            Sign in
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="lg:hidden border-t border-black/10 mt-4 py-4 px-4">
          <button
            className="w-full rounded-2xl border-2 border-dashed border-black bg-white px-6 py-3 font-semibold uppercase text-black text-base transition-all duration-300 hover:translate-x-[-4px] hover:translate-y-[-4px] hover:rounded-3xl hover:shadow-[4px_4px_0px_black] active:translate-x-[0px] active:translate-y-[0px] active:rounded-xl active:shadow-none"
            onClick={() => navigate('/Dashboard')}
          >
            Sign in
          </button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
