import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Search, Ticket, Trash } from 'lucide-react';

const TicketDashboard = () => {
  const [tickets, setTickets] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const ticketsPerPage = 6;
  const [filter, setFilter] = useState('all');

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return date.toLocaleDateString('en-US', options);
  };

  const fetchTickets = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://leap.up.railway.app/get-tickets');
      setTickets(response.data);
    } catch (error) {
      setError('Failed to fetch tickets. Please try again later.');
      console.error('Error fetching tickets:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const handleUpdateTicket = async () => {
    try {
      await axios.post('https://leap.up.railway.app/update-ticket', {
        id: selectedTicket.id,
        context: answer,
      });
      setIsDialogOpen(false);
      setAnswer('');
      setSelectedTicket(null);
      fetchTickets();
    } catch (error) {
      console.error('Error updating ticket:', error.message);
    }
  };

  const handleDeleteTicket = async (ticketId) => {
    try {
      await axios.delete(`https://leap.up.railway.app/delete-ticket/${ticketId}`);
      fetchTickets();
    } catch (error) {
      console.error('Error deleting ticket:', error.message);
    }
  };

  const handleEnhanceAnswer = async () => {
    console.log(answer)
    const prompt = answer+".Rewrite this in professional way."
    try {
      const response = await axios.post('https://leap.up.railway.app/use-ai', {
        prompt: prompt,
      });
      setAnswer(response.data.choices[0].message.content);
    } catch (error) {
      console.error('Error enhancing answer:', error.message);
    }
  };

  const filteredTickets = tickets
    .filter(ticket => 
      filter === 'all' ||
      (filter === 'resolved' && ticket.status === true) ||
      (filter === 'not-resolved' && ticket.status === false)
    )
    .filter(ticket => 
      ticket.question.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = filteredTickets.slice(indexOfFirstTicket, indexOfLastTicket);

  const totalPages = Math.ceil(filteredTickets.length / ticketsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="flex flex-col w-full min-h-screen bg-gray-50">
        <header className="flex items-center h-16 px-6 bg-white shadow-md rounded-xl">
          <div className="flex items-center w-full gap-4">
            <form className="relative flex-grow">
              <Search className="absolute left-3 top-3 h-4 w-4 text-gray-500" />
              <input
                className="pl-10 pr-4 py-2 border rounded-full shadow-sm focus:outline-none focus:ring focus:ring-blue-300 w-full"
                placeholder="Search tickets..."
                type="search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
            <button
              className={`px-4 py-2 rounded-full ${filter === 'all' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
              onClick={() => handleFilterChange('all')}
            >
              All
            </button>
            <button
              className={`px-4 py-2 rounded-full ${filter === 'resolved' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
              onClick={() => handleFilterChange('resolved')}
            >
              Resolved
            </button>
            <button
              className={`px-4 py-2 rounded-full ${filter === 'not-resolved' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
              onClick={() => handleFilterChange('not-resolved')}
            >
              Not Resolved
            </button>
          </div>
        </header>
        <main className="flex-1 p-6">
          {loading && <p>Loading tickets...</p>}
          {error && <p className="text-red-500">{error}</p>}
          <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {currentTickets.map((ticket) => (
              <div key={ticket.id} className="bg-white shadow-md rounded-xl p-6">
                <div className="flex flex-col gap-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      <div className="flex-shrink-0 rounded-full w-12 h-12 bg-green-200 flex items-center justify-center text-xl">🤔</div>
                      <div className="font-bold text-md line-clamp-2">{ticket.question}</div>
                    </div>
                  </div>
                  <div
                    className="text-gray-700 line-clamp-2"
                    dangerouslySetInnerHTML={{ __html: ticket.chat_answer }}
                  />
                  <div className="text-gray-500 text-sm">Created on {formatDate(ticket.created_at)} by Andrew</div>
                  <div className="text-gray-500 text-sm">Ticket ID: {ticket.id}</div>
                  <div className='flex justify-between'>
                  <button
                    className="self-start bg-blue-500 text-white rounded-full px-4 py-2 mt-2"
                    onClick={() => {
                      setSelectedTicket(ticket);
                      setAnswer(ticket.context);
                      setIsDialogOpen(true);
                    }}
                  >
                    View
                  </button>
                  <button onClick={() => handleDeleteTicket(ticket.id)}>
                      <Trash className="text-red-500" />
                    </button>
                    </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-6 space-x-2">
            <button
              className="bg-gray-200 text-gray-700 rounded-xl px-4 py-2"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                className={`px-4 py-2 rounded-full ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className="bg-gray-200 text-gray-700 rounded-xl px-4 py-2"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </main>
      </div>
      {isDialogOpen && selectedTicket && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 modal-enter-active">
          <div className="bg-white rounded-2xl p-8 max-w-3xl w-full shadow-lg">
            <h2 className="text-xl font-bold mb-4">Ticket Details</h2>
            <p className="text-gray-600 mb-4"><strong>Question:</strong> {selectedTicket.question}</p>
            <div
              className="text-gray-600 mb-4"
              dangerouslySetInnerHTML={{ __html: `<strong>Answer:</strong> ${selectedTicket.chat_answer}` }}
            />
            <p className="text-gray-600 mb-4"><strong>Ticket ID:</strong> {selectedTicket.id}</p>
            <p className="text-gray-600 mb-4"><strong>Status:</strong> {selectedTicket.status ? 'Resolved' : 'Not Resolved'}</p>
            <textarea
              className="w-full border rounded-2xl p-4 mb-4"
              id="answer"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              rows="4"
            />
            <div className="flex justify-end gap-4">
              <button
                className="bg-gray-200 text-gray-700 rounded-xl px-4 py-2"
                onClick={() => setIsDialogOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white rounded-xl px-4 py-2"
                onClick={handleUpdateTicket}
              >
                Save Answer
              </button>
              <button
                className="bg-green-500 text-white rounded-xl px-4 py-2"
                onClick={handleEnhanceAnswer}
              >
                Enhance Answer
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TicketDashboard;
