import React from 'react';
import { FaCaretDown } from "react-icons/fa";

const FaqHome = () => {
  return (
    <div className="bg-white font-lato w-full p-5 mx-auto">
      <div className="max-w-3xl bg-white w-4/5 p-0 mx-auto">
        <header className="mb-14">
          <h2 className="text-5xl text-black mt-10 mb-5 font-bold">FAQs</h2>
          <p className="text-2xl text-gray-700">Answers to the most frequently asked questions.</p>
        </header>

        <details className="cursor-pointer">
          <summary className="flex justify-between items-center text-xl text-black mb-6">
            <h4 className="w-full">How long does it take to set up LeapCrew AI?</h4>
            <FaCaretDown className="mt-1 text-black" />
          </summary>
          <p className="max-w-lg text-gray-700 text-lg leading-7 tracking-wide mb-10 pl-2">
            Setting up LeapCrew AI is quick and straightforward. Simply sign up with your admin account and anyone with your company domain can use their email to log in to query our chatbot.
          </p>
        </details>

        <hr className="border-gray-300 my-5" />

        <details className="cursor-pointer">
          <summary className="flex justify-between items-center text-xl text-black mb-6">
            <h4 className="w-full">What types of documentation can be uploaded?</h4>
            <FaCaretDown className="mt-1 text-black" />
          </summary>
          <p className="max-w-lg text-gray-700 text-lg leading-7 tracking-wide mb-10 pl-2">
            You can upload various types of documentation in PDF Format, including policy manuals, employee handbooks, FAQ documents, and other internal resources.
          </p>
        </details>

        <hr className="border-gray-300 my-5" />

        <details className="cursor-pointer">
          <summary className="flex justify-between items-center text-xl text-black mb-6">
            <h4 className="w-full">Can I upgrade from the Standard Plan to the Enterprise Plan?</h4>
            <FaCaretDown className="mt-1 text-black" />
          </summary>
          <p className="max-w-lg text-gray-700 text-lg leading-7 tracking-wide mb-10 pl-2">
            Yes, you can upgrade to the Enterprise Plan at any time as your company grows. Please contact our support team to assist with the transition.
          </p>
        </details>

        <hr className="border-gray-300 my-5" />

        <details className="cursor-pointer">
          <summary className="flex justify-between items-center text-xl text-black mb-6">
            <h4 className="w-full">How does LeapCrew AI handle unanswered questions?</h4>
            <FaCaretDown className="mt-1 text-black" />
          </summary>
          <p className="max-w-lg text-gray-700 text-lg leading-7 tracking-wide mb-10 pl-2">
            If our LLM lacks the context to answer a question, it creates a ticket for your HR team to manually respond. This way, complex questions can be addressed directly by HR, while the LLM learns and improves over time. You can also see a history of questions answered in the past.
          </p>
        </details>

        <hr className="border-gray-300 my-5" />

        <details className="cursor-pointer">
          <summary className="flex justify-between items-center text-xl text-black mb-6">
            <h4 className="w-full">How does LeapCrew AI ensure data security?</h4>
            <FaCaretDown className="mt-1 text-black" />
          </summary>
          <p className="max-w-lg text-gray-700 text-lg leading-7 tracking-wide mb-10 pl-2">
            We prioritize data security and use industry-standard encryption to protect your information. Our LLM is hosted on our own secure servers, and we do not use third-party APIs like Open AI. We also comply with all relevant data protection regulations to ensure your data is safe.
          </p>
        </details>

        <hr className="border-gray-300 my-5" />

        <details className="cursor-pointer">
          <summary className="flex justify-between items-center text-xl text-black mb-6">
            <h4 className="w-full">What customization options are available with the Enterprise Plan?</h4>
            <FaCaretDown className="mt-1 text-black" />
          </summary>
          <p className="max-w-lg text-gray-700 text-lg leading-7 tracking-wide mb-10 pl-2">
            Customization options are available with the Enterprise Plan. Please contact us to discuss your specific requirements.
          </p>
        </details>
      </div>
    </div>
  );
};

export default FaqHome;
