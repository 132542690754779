import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { ClerkProvider } from '@clerk/clerk-react'
// Import your publishable key

const PUBLISHABLE_KEY = 'pk_test_dWx0aW1hdGUtc25hcHBlci04My5jbGVyay5hY2NvdW50cy5kZXYk'
 
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <App />
    </ClerkProvider>
  </React.StrictMode>
)
