import React from 'react';
import LandingPage from '../Component/LandingPage';


const Home = () => {

  return (
    <div>
      {/* <header> */}
        <LandingPage/>
        {/* <Dashboard/>
        <h1>React and Flask Interaction</h1>
        <textarea
          type="text"
          value={question}
          onChange={handleQuestionChange}
          placeholder="Ask about Elon Musk"
        />
        <button onClick={fetchAnswer}>Get Answer</button>
        <p>Answer: {answer}</p>
      </header> */}
    </div>
  );
}

export default Home;
