import React from 'react';
import { useNavigate } from 'react-router-dom';

const PricingCard = () => {
    const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-white text-black flex flex-col items-center justify-center">
      <div className="text-center mb-10">
        <h1 className="text-4xl font-bold">Pricing</h1>
        <p className="mt-2 text-gray-600">Choose the plan for your business needs.</p>
      </div>
      <div className="flex flex-col md:flex-row justify-center gap-10">
        <div className="bg-white border border-gray-300 rounded-2xl shadow-md p-6 max-w-sm">
          <h2 className="text-xl font-semibold mb-2">Standard</h2>
          <div className="text-5xl font-bold mb-4">$400<span className="text-xl font-normal"> / month</span></div>
          <p className="text-gray-600 mb-4">Suitable for companies with up to 500 employees.</p>
          <button
      className="w-full rounded-2xl border-2 border-dashed border-black bg-white px-6 py-3 font-semibold uppercase text-black text-base transition-all duration-300 hover:translate-x-[-4px] hover:translate-y-[-4px] hover:rounded-3xl hover:shadow-[4px_4px_0px_black] active:translate-x-[0px] active:translate-y-[0px] active:rounded-xl active:shadow-none"
      onClick={() => navigate('/SignupForm')}
    >
      Get Started
    </button>
          {/* <button className="w-full bg-black text-white py-2 rounded-md mb-4">Get Started</button> */}
          <p className="text-gray-600 text-sm mb-4">30 day money-back guarantee.</p>
          <hr className="my-4" />
          <ul className="text-left space-y-2">
            <li>Unlimited Employee Queries</li>
            <li>Max 100 Document Uploads per Month</li>
            <li>Smart Ticketing for Unanswered Questions</li>
            <li>Email Support</li>
          </ul>
        </div>
        <div className="bg-white border border-gray-300 rounded-2xl shadow-md p-6 max-w-sm">
          <h2 className="text-xl font-semibold mb-2">Enterprise</h2>
          <div className="text-5xl font-bold mb-4">Custom</div>
          <p className="text-gray-600 mb-4">Suitable for companies that require custom solutions or have more than 500 employees.</p>
          <button
      className="w-full rounded-2xl border-2 border-dashed border-black bg-white px-10 py-3 font-semibold uppercase text-black text-base transition-all duration-300 hover:translate-x-[-4px] hover:translate-y-[-4px] hover:rounded-3xl hover:shadow-[4px_4px_0px_black] active:translate-x-[0px] active:translate-y-[0px] active:rounded-xl active:shadow-none"
      onClick={() => navigate('/SignupForm')}
    >
      Contact Us
    </button>
          {/* <button className="w-full bg-black text-white py-2 rounded-md mb-4">Contact Us</button> */}
          <hr className="my-4" />
          <ul className="text-left space-y-2">
            <li>Unlimited Employee Queries</li>
            <li>Unlimited Document Uploads per Month</li>
            <li>Smart Ticketing for Unanswered Questions</li>
            <li>Access to Analytics and Reports</li>
            <li>24/7 Support</li>
            <li>Custom Integrations and Features</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
