import { useState } from "react";
import {
  Calendar,
  User,
  X,
  Edit,
  Trash2,
  PlusCircle,
} from "lucide-react";

export default function Task() {
  const [tasks, setTasks] = useState([
    {
      id: "task-1",
      title: "Finalize quarterly report",
      description: "Gather data, analyze trends, and draft the report.",
      dueDate: "2023-06-30",
      priority: "high",
      createdAt: "2023-05-01",
      updatedAt: "2023-05-15",
      assignee: "John Doe",
      tags: ["finance", "reporting"],
      comments: [
        {
          id: "comment-1",
          content: "Remember to include the revenue projections.",
          author: "Jane Smith",
          createdAt: "2023-05-10",
        },
        {
          id: "comment-2",
          content: "Let me know if you need any help with the analysis.",
          author: "Michael Johnson",
          createdAt: "2023-05-12",
        },
      ],
    },
    {
      id: "task-2",
      title: "Redesign company website",
      description: "Update the layout, color scheme, and content.",
      dueDate: "2023-07-15",
      priority: "medium",
      status: "to-do",
      createdAt: "2023-05-05",
      updatedAt: "2023-05-10",
      assignee: "Sarah Lee",
      tags: ["marketing", "design"],
      comments: [
        {
          id: "comment-3",
          content: "Let's discuss the new branding guidelines.",
          author: "David Kim",
          createdAt: "2023-05-08",
        },
      ],
    },
    {
      id: "task-3",
      title: "Implement new security measures",
      description: "Research and implement updated security protocols.",
      dueDate: "2023-08-01",
      priority: "high",
      createdAt: "2023-05-15",
      updatedAt: "2023-05-20",
      assignee: "Emily Chen",
      tags: ["security", "it"],
      comments: [
        {
          id: "comment-4",
          content: "Make sure to test the new firewall settings.",
          author: "Alex Tan",
          createdAt: "2023-05-18",
        },
        {
          id: "comment-5",
          content: "I can help with the implementation if needed.",
          author: "Olivia Nguyen",
          createdAt: "2023-05-19",
        },
      ],
    },
    {
      id: "task-4",
      title: "Organize team building event",
      description: "Plan and organize a team building event for the department.",
      dueDate: "2023-09-10",
      priority: "low",
      createdAt: "2023-06-01",
      updatedAt: "2023-06-02",
      assignee: "Alice Brown",
      tags: ["hr", "event"],
      comments: [
        {
          id: "comment-6",
          content: "Consider a location that is convenient for everyone.",
          author: "Robert Davis",
          createdAt: "2023-06-02",
        },
      ],
    },
    {
      id: "task-5",
      title: "Develop mobile app prototype",
      description: "Create a prototype of the new mobile app.",
      dueDate: "2023-07-25",
      priority: "medium",
      createdAt: "2023-05-20",
      updatedAt: "2023-06-01",
      assignee: "Chris Evans",
      tags: ["development", "mobile"],
      comments: [
        {
          id: "comment-7",
          content: "Ensure the prototype includes the main features.",
          author: "Natalie Portman",
          createdAt: "2023-06-01",
        },
        {
          id: "comment-8",
          content: "Test the app on different devices.",
          author: "Samuel Jackson",
          createdAt: "2023-06-02",
        },
      ],
    },
    {
      id: "task-6",
      title: "Update employee handbook",
      description: "Revise and update the employee handbook with new policies.",
      dueDate: "2023-08-20",
      priority: "high",
      createdAt: "2023-06-10",
      updatedAt: "2023-06-12",
      assignee: "Jessica Alba",
      tags: ["hr", "documentation"],
      comments: [
        {
          id: "comment-9",
          content: "Include the new remote work policy.",
          author: "Paul Walker",
          createdAt: "2023-06-12",
        },
      ],
    },
  ]);
  const [newTask, setNewTask] = useState({
    title: "",
    description: "",
    dueDate: "",
    priority: "low",
    status: "to-do",
    assignee: "",
    tags: [],
    comments: [],
  });
  const [editingTask, setEditingTask] = useState(null);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);

  const handleAddTask = () => {
    setShowAddTaskModal(true);
  };

  const handleSaveNewTask = () => {
    const newTaskId = `task-${tasks.length + 1}`;
    const newTaskData = {
      ...newTask,
      id: newTaskId,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
    setTasks([...tasks, newTaskData]);
    setNewTask({
      title: "",
      description: "",
      dueDate: "",
      priority: "low",
      status: "to-do",
      assignee: "",
      tags: [],
      comments: [],
    });
    setShowAddTaskModal(false);
  };

  const handleEditTask = (taskId) => {
    const taskToEdit = tasks.find((task) => task.id === taskId);
    setEditingTask(taskToEdit);
  };

  const handleUpdateTask = () => {
    const updatedTasks = tasks.map((task) =>
      task.id === editingTask.id ? editingTask : task
    );
    setTasks(updatedTasks);
    setEditingTask(null);
  };

  const handleDeleteTask = (taskId) => {
    const updatedTasks = tasks.filter((task) => task.id !== taskId);
    setTasks(updatedTasks);
  };

  const handleAddComment = (taskId, comment) => {
    const updatedTasks = tasks.map((task) => {
      if (task.id === taskId) {
        return {
          ...task,
          comments: [...task.comments, comment],
          updatedAt: new Date().toISOString(),
        };
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  return (
    <div className="w-full max-w-6xl mx-auto px-4 py-8 md:px-6 md:py-12">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold text-gray-800">Task Management</h1>
        <button
          onClick={handleAddTask}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          <PlusCircle className="mr-2" /> Add Task
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {tasks.map((task) => (
          <div key={task.id} className="p-4 bg-white rounded-xl shadow-md">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-semibold text-gray-900">{task.title}</h2>
              <div className="flex items-center gap-2">
                <span
                  className={`px-2 py-1 text-sm font-semibold rounded-full ${
                    task.priority === "high"
                      ? "bg-red-100 text-red-600"
                      : task.priority === "medium"
                      ? "bg-yellow-100 text-yellow-600"
                      : "bg-green-100 text-green-600"
                  }`}
                >
                  {task.priority}
                </span>
  
              </div>
            </div>
            <p className="text-gray-700 mb-4">{task.description}</p>
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <Calendar className="w-4 h-4 text-gray-500" />
                <span className="text-gray-600 text-sm">{task.dueDate}</span>
              </div>
              <div className="flex items-center gap-2">
                <User className="w-4 h-4 text-gray-500" />
                <span className="text-gray-600 text-sm">{task.assignee}</span>
              </div>
            </div>
            <div className="flex flex-wrap gap-2 mb-4">
              {task.tags.map((tag) => (
                <span key={tag} className="px-2 py-1 bg-gray-100 text-gray-600 rounded-full text-sm">
                  {tag}
                </span>
              ))}
            </div>
            <div className="flex items-center justify-between">
              <button
                onClick={() => handleEditTask(task.id)}
                className="flex items-center px-4 py-2 bg-gray-200 text-gray-700 rounded-xl hover:bg-gray-300"
              >
                <Edit className="mr-2" /> Edit
              </button>
              <button
                onClick={() => handleDeleteTask(task.id)}
                className="flex items-center px-4 py-2 bg-red-500 text-white rounded-xl hover:bg-red-600"
              >
                <Trash2 className="mr-2" /> Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      {editingTask && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 shadow-lg">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-xl font-semibold text-gray-900">Edit Task</h3>
              <X
                className="w-6 h-6 cursor-pointer text-gray-600 hover:text-gray-900"
                onClick={() => setEditingTask(null)}
              />
            </div>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="title">Title</Label>
                <Input
                  id="title"
                  value={editingTask.title}
                  onChange={(e) =>
                    setEditingTask({ ...editingTask, title: e.target.value })
                  }
                  className="p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="description">Description</Label>
                <Textarea
                  id="description"
                  value={editingTask.description}
                  onChange={(e) =>
                    setEditingTask({
                      ...editingTask,
                      description: e.target.value,
                    })
                  }
                  className="p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="grid md:grid-cols-2 gap-4">
                <div className="grid gap-2">
                  <Label htmlFor="dueDate">Due Date</Label>
                  <Input
                    id="dueDate"
                    type="date"
                    value={editingTask.dueDate}
                    onChange={(e) =>
                      setEditingTask({
                        ...editingTask,
                        dueDate: e.target.value,
                      })
                    }
                    className="p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="grid gap-2">
                  <Label htmlFor="priority">Priority</Label>
                  <select
                    id="priority"
                    value={editingTask.priority}
                    onChange={(e) =>
                      setEditingTask({
                        ...editingTask,
                        priority: e.target.value,
                      })
                    }
                    className="p-2 border border-gray-300 rounded-md"
                  >
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </select>
                </div>
              </div>
              <div className="grid md:grid-cols-2 gap-4">
                <div className="grid gap-2">
                  <Label htmlFor="status">Status</Label>
                  <select
                    id="status"
                    value={editingTask.status}
                    onChange={(e) =>
                      setEditingTask({
                        ...editingTask,
                        status: e.target.value,
                      })
                    }
                    className="p-2 border border-gray-300 rounded-md"
                  >
                    <option value="to-do">To Do</option>
                    <option value="in-progress">In Progress</option>
                    <option value="done">Done</option>
                  </select>
                </div>
                <div className="grid gap-2">
                  <Label htmlFor="assignee">Assignee</Label>
                  <Input
                    id="assignee"
                    value={editingTask.assignee}
                    onChange={(e) =>
                      setEditingTask({
                        ...editingTask,
                        assignee: e.target.value,
                      })
                    }
                    className="p-2 border border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="tags">Tags</Label>
                <Input
                  id="tags"
                  value={editingTask.tags.join(", ")}
                  onChange={(e) =>
                    setEditingTask({
                      ...editingTask,
                      tags: e.target.value.split(", "),
                    })
                  }
                  className="p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="comments">Comments</Label>
                <div className="grid gap-4">
                  {editingTask.comments.map((comment) => (
                    <div
                      key={comment.id}
                      className="bg-gray-100 p-4 rounded-lg"
                    >
                      <div className="flex items-center justify-between mb-2">
                        <span className="font-medium">{comment.author}</span>
                        <span className="text-gray-500 text-sm">
                          {new Date(comment.createdAt).toLocaleString()}
                        </span>
                      </div>
                      <p>{comment.content}</p>
                    </div>
                  ))}
                  <div className="flex items-center gap-2">
                    <Input
                      placeholder="Add a comment..."
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          const newComment = {
                            id: `comment-${editingTask.comments.length + 1}`,
                            content: e.target.value,
                            author: "You",
                            createdAt: new Date().toISOString(),
                          };
                          handleAddComment(editingTask.id, newComment);
                          e.target.value = "";
                        }
                      }}
                      className="p-2 border border-gray-300 rounded-md"
                    />
                    <button
                      onClick={() =>
                        handleAddComment(editingTask.id, {
                          id: `comment-${editingTask.comments.length + 1}`,
                          content: newTask.comments[0],
                          author: "You",
                          createdAt: new Date().toISOString(),
                        })
                      }
                      className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
                    >
                      Add Comment
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end mt-4">
              <button
                onClick={() => setEditingTask(null)}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateTask}
                className="ml-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
      {showAddTaskModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 shadow-lg">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-xl font-semibold text-gray-900">Add New Task</h3>
              <X
                className="w-6 h-6 cursor-pointer text-gray-600 hover:text-gray-900"
                onClick={() => setShowAddTaskModal(false)}
              />
            </div>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="title">Title</Label>
                <Input
                  id="title"
                  value={newTask.title}
                  onChange={(e) =>
                    setNewTask({ ...newTask, title: e.target.value })
                  }
                  className="p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="description">Description</Label>
                <Textarea
                  id="description"
                  value={newTask.description}
                  onChange={(e) =>
                    setNewTask({ ...newTask, description: e.target.value })
                  }
                  className="p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="grid md:grid-cols-2 gap-4">
                <div className="grid gap-2">
                  <Label htmlFor="dueDate">Due Date</Label>
                  <Input
                    id="dueDate"
                    type="date"
                    value={newTask.dueDate}
                    onChange={(e) =>
                      setNewTask({ ...newTask, dueDate: e.target.value })
                    }
                    className="p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="grid gap-2">
                  <Label htmlFor="priority">Priority</Label>
                  <select
                    id="priority"
                    value={newTask.priority}
                    onChange={(e) =>
                      setNewTask({ ...newTask, priority: e.target.value })
                    }
                    className="p-2 border border-gray-300 rounded-md"
                  >
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </select>
                </div>
              </div>
              <div className="flex items-center justify-end mt-4">
                <button
                  onClick={() => setShowAddTaskModal(false)}
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveNewTask}
                  className="ml-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Save Task
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function Label({ children, htmlFor }) {
  return (
    <label htmlFor={htmlFor} className="block text-sm font-medium text-gray-700">
      {children}
    </label>
  );
}

function Input({ className, ...props }) {
  return <input className={`block w-full p-2 border border-gray-300 rounded-md ${className}`} {...props} />;
}

function Textarea({ className, ...props }) {
  return <textarea className={`block w-full p-2 border border-gray-300 rounded-md ${className}`} {...props} />;
}
