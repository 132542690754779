import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useUser } from "@clerk/clerk-react";
import axios from 'axios';

const questions = [
  "What is your full name?",
  "What is your Linkedin?",
  "Which company do you work for?",
  "How many employees does your company have?",
  "What industry does your company operate in?",
  "Do you have any specific feature requests, customizations, or additional comments?",
  "Which plan suits your need?"
];

const chatMessages = [
  { sender: 'user', text: "If I take some annual leave within my probation period, will that extend my probation period?" },
  { sender: 'bot', text: "Hey there! I'm actually not sure about this question. Let me raise a ticket to ask someone in HR and get back to you on that one." },
  { sender: 'user', text: "Thank you :)" }
];

const SignupForm = () => {
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [orgExists, setOrgExists] = useState(false);
  const [requestingDemo, setRequestingDemo] = useState(false);
  const [demoRequested, setDemoRequested] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    if (!user?.id) {
      navigate('/Dashboard');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (showChat) {
      chatMessages.forEach((message, index) => {
        setTimeout(() => {
          setChatHistory(prevHistory => [...prevHistory, message]);
        }, index * 2000);
      });
    }
  }, [showChat]);

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const response = await axios.get(`https://leap.up.railway.app/fetch-organisation/${user.id}`);
        if (response.status === 200) {
          const fetchedAnswers = {
            0: response.data.q1,
            1: response.data.q2,
            2: response.data.q3,
            3: response.data.q4,
            4: response.data.q5,
            5: response.data.q6,
            6: response.data.q7
          };
          setAnswers(fetchedAnswers);
          setOrgExists(true);
        } else {
          throw new Error("Failed to fetch answers");
        }
      } catch (error) {
        console.error("Error fetching answers:", error);
        setOrgExists(false);
      }
    };

    if (user?.id) {
      fetchAnswers();
    }
  }, [user]);

  const handleNext = async (e) => {
    e.preventDefault();
    if (step < questions.length - 1) {
      setStep(step + 1);
      if (step === 0) {
        setShowChat(true);
      }
    } else {
      setLoading(true);
      setRequestingDemo(true);

      try {
        const endpoint = orgExists ? 'update-organisation' : 'store-organisation';
        const response = await axios.post(`https://leap.up.railway.app/${endpoint}`, {
          creator_id: user.id,
          q1: answers[0],
          q2: answers[1],
          q3: answers[2],
          q4: answers[3],
          q5: answers[4],
          q6: answers[5],
          q7: answers[6]
        });

        if (response.status === 200) {
          console.log(`Organization ${orgExists ? 'updated' : 'created'} successfully:`, response.data);
          setRequestingDemo(false);
          setDemoRequested(true);
          setTimeout(() => {
            navigate('/home');
          }, 3000);
        } else {
          throw new Error(`Failed to ${orgExists ? 'update' : 'create'} organization`);
        }
      } catch (error) {
        console.error(`Error ${orgExists ? 'updating' : 'creating'} organisation:`, error);
        setLoading(false);
        setRequestingDemo(false);
      }
    }
  };

  const handleChange = (e) => {
    setAnswers({ ...answers, [step]: e.target.value });
  };

  const handleSelectChange = (e) => {
    setAnswers({ ...answers, [step]: e.target.value });
  };

  const progress = ((step + 1) / questions.length) * 100;

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-100 p-4">
      {!showChat ? (
        <div className="md:w-1/2 flex flex-col justify-center p-8">
          <h1 className="text-4xl font-bold text-center mb-4 text-gray-800">LeapCrew AI</h1>
          <p className="text-center text-gray-600 mb-8">
            Great! Let’s customise your experience
            <br />
            Just a few questions to tailor our onboarding LLM to your needs.
          </p>
        </div>
      ) : (
        <div className="md:w-1/2 flex flex-col justify-center p-8">
          <div className="chat-container space-y-4">
            {chatHistory.map((message, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
              >
                <div className={`p-4 mb-4 rounded-xl shadow-md max-w-xs ${message.sender === 'user' ? 'bg-white text-gray-800' : 'bg-indigo-600 text-white'}`}>
                  <p>{message.text}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      )}
      <motion.div 
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        className="md:w-1/2 bg-white bg-opacity-30 backdrop-blur-lg rounded-xl shadow-lg p-8 flex flex-col justify-center"
      >
        {!loading && !requestingDemo && !demoRequested ? (
          <>
            <div className="relative w-full h-2 bg-gray-300 rounded-full mb-6 overflow-hidden">
              <motion.div
                className="absolute top-0 h-2 bg-indigo-600 rounded-full"
                initial={{ width: 0 }}
                animate={{ width: `${progress}%` }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
              ></motion.div>
            </div>
            <form onSubmit={handleNext} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {step + 1}. {questions[step]}
                </label>
                {step !== 6 ? (
                  <input
                    type="text"
                    value={answers[step] || ''}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white bg-opacity-80 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-md"
                  />
                ) : (
                  <select
                    value={answers[step] || ''}
                    onChange={handleSelectChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white bg-opacity-80 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-md"
                  >
                    <option value="">Select an option</option>
                    <option value="standard">Standard</option>
                    <option value="custom">Custom</option>
                  </select>
                )}
              </div>
              <div className="flex justify-between items-center">
                {step > 0 && (
                  <button
                    type="button"
                    onClick={() => setStep(step - 1)}
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-full shadow hover:bg-gray-400 focus:outline-none transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-md"
                  >
                    Back
                  </button>
                )}
                <button
                  type="submit"
                  className="px-4 py-2 bg-indigo-600 text-white rounded-full shadow hover:bg-indigo-700 focus:outline-none transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-md"
                >
                  {step < questions.length - 1 ? "Next" : "Submit"}
                </button>
              </div>
            </form>
          </>
        ) : requestingDemo ? (
          <div className="flex flex-col items-center">
            <svg
              className="animate-spin h-10 w-10 text-indigo-600 mb-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <p className="text-lg text-gray-700">Requesting demo...</p>
          </div>
        ) : demoRequested ? (
          <div className="flex flex-col items-center">
            <p className="text-lg text-gray-700">Demo requested</p>
          </div>
        ) : null}
      </motion.div>
    </div>
  );
};

export default SignupForm;
