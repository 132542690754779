import React, { useState, useEffect } from 'react';
import { Flag } from 'lucide-react';
import { useUser } from "@clerk/clerk-react";

const ChatBot = () => {
    const [question, setQuestion] = useState('');
    const [savedQuestion, setSavedQuestion] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [answer, setAnswer] = useState('');
    const [messages, setMessages] = useState([{
        sender: 'bot',
        text: "Hello! I'm the Chatbot, an AI assistant created by LeapCrew AI. How can I help you today?",
        time: '10:30 AM'
    }]);
    const [isLoading, setIsLoading] = useState(false); // State to handle loading animation and button disabling
    const { user } = useUser();

    useEffect(() => {
        setSessionId(generateSessionId());
    }, []);

    const generateSessionId = () => {
        const getRandomNumber = (length) => Array.from({ length }, () => Math.floor(Math.random() * 10)).join('');
        const getRandomLetters = (length) => Array.from({ length }, () => String.fromCharCode(97 + Math.floor(Math.random() * 26))).join('');
        return `${getRandomNumber(4)}${getRandomLetters(4)}${getRandomLetters(4)}${getRandomNumber(4)}`;
    };

    const handleQuestionChange = event => {
        setQuestion(event.target.value);
        setSavedQuestion(event.target.value);
    };

    const storeSession = async () => {
        const sessionData = {
            sessionId: sessionId,
            userId: user.id,
            extracted: false
        };

        try {
            const response = await fetch('https://leap.up.railway.app/store-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(sessionData),
            });
            const result = await response.json();
            console.log('Store session response:', result);
        } catch (error) {
            console.error('Error storing session:', error);
        }
    };

    const storeChat = async (role, message) => {
        const chatData = {
            userId: user.id,
            sessionId: sessionId,
            role: role,
            message: message
        };

        try {
            await fetch('https://leap.up.railway.app/store-chat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(chatData),
            });
        } catch (error) {
            console.error('Error storing chat:', error);
        }
    };

    const fetchAnswer = async () => {
        setIsLoading(true); // Start loading
        const fullQuestion = question + `Use \n for new lines when required. Greet the user if they say "hi" and give topics they can ask questions about, not answers. Use numerical lists instead of tables for any lists. Only share answer to current query`;

        // Add user's question to the messages
        setMessages(messages => [
            ...messages,
            { sender: 'user', text: question, time: new Date().toLocaleTimeString() }
        ]);

        // Store the user's question in the database
        storeChat('user', question);

        try {
            const response = await fetch('https://leap-production.up.railway.app/api/data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ question: fullQuestion, session_id: sessionId }),
            });

            let text = await response.text();

            // Various text replacements
            text = text.replace(/\\u00a3/g, '£')
                       .replace(/\u00a3/g, '£')
                       .replace(/\\n/g, '<br>')
                       .replace(/https:\/(?!\/)/g, 'https://')
                       .replace(/\\(.)/g, '$1');

            const answerText = text.slice(1, -1);
            setAnswer(answerText);
            setMessages(messages => [
                ...messages,
                { sender: 'bot', text: answerText, time: new Date().toLocaleTimeString() }
            ]);

            // Store the system's response in the database
            storeChat('bot', answerText);
            if (messages.length === 1) {
                storeSession();
            }

            setQuestion(''); // Clear the input after sending
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const reportChat = async (setFetchedQuestion, setAiResponse) => {
        setIsLoading(true); // Start loading
        try {
            const reportResponse = await fetch('https://leap-production.up.railway.app/api/data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ question: "What did i ask you? If I didn't ask anything specific like greeting just say No", session_id: sessionId }),
            });

            const reportResult = await reportResponse.text();
            const reportEnchanced= reportResult+"Just return question"

            const aiResponse = await fetch('https://leap.up.railway.app/use-ai', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt: reportEnchanced }),
            });

            const aiResult = await aiResponse.json();

        // Ensure the choices array and its properties exist before accessing them
        if (aiResult && aiResult.choices && aiResult.choices.length > 0) {
            const aiContent = aiResult.choices[0].message.content;
            setFetchedQuestion(aiContent)
        } else {
            console.error('Invalid AI response format:', aiResult);
            setAiResponse('Error: Unable to fetch AI response');
        }

        setIsLoading(false); // Stop loading
    } catch (error) {
        console.error('Error reporting chat:', error);
        setIsLoading(false); // Stop loading
    }
};

    const confirmReport = async (reportData) => {
        setIsLoading(true); // Start loading
        try {
            const storeReportResponse = await fetch('https://leap.up.railway.app/store-report', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reportData),
            });

            await storeReportResponse.json();
            window.location.reload(); // Reload the page after successful report
        } catch (error) {
            console.error('Error confirming report:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    return (
        <div className="flex h-[90vh] max-h-screen flex-col rounded-2xl border bg-white shadow-lg dark:border-gray-800 dark:bg-gray-950 justify-center">
            {/* Header */}
            <header className="flex items-center gap-4 border-b px-4 py-3 dark:border-gray-800">
                <span className="relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full">
                    <span className="flex h-full w-full items-center justify-center rounded-full bg-muted">CB</span>
                </span>
                <h3 className="text-lg font-semibold">Chatbot</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">Online</p>
            </header>
            {/* Messages */}
            <div className="flex-1 overflow-auto p-4">
                <div className="grid gap-4">
                    {messages.map((message, index) => (
                        <div key={index} className={`flex ${message.sender === 'bot' ? 'items-start' : 'justify-end items-start'} gap-3`}>
                            <span className="relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full">
                                <span className="flex h-full w-full items-center justify-center rounded-full bg-muted">{message.sender === 'bot' ? 'CB' : 'JD'}</span>
                            </span>
                            <div className="max-w-[75%] space-y-2">
                                <div className={`p-4 mb-4 rounded-xl shadow-md border-l-[3px] border-b-[2px] border-black ${message.sender === 'user' ? 'bg-white text-black border-l-4' : 'bg-white text-black border-l-4'}`}
                                // <div className={`rounded-xl shadow-md max-w-xs border-l-[3px] border-b-[2px] border-black ${message.sender === 'bot' ? 'border-black bg-gray-100' : 'border-white bg-gray-900'} border-[1.5px] p-3 text-sm ${message.sender === 'bot' ? 'dark:bg-gray-800 dark:text-gray-50' : 'text-gray-50'}`}
                                    dangerouslySetInnerHTML={{ __html: message.text }}
                                />
                                <p className="text-xs text-gray-500 dark:text-gray-400">{message.time}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* Input Area */}
            <div className="flex items-stretch border-t px-4 py-3 dark:border-gray-800">
                <ReportButton question={savedQuestion} messages={messages} reportChat={reportChat} confirmReport={confirmReport} isLoading={isLoading} userID={user.id} sessionId={sessionId}/>
                <form className="flex items-center gap-2 w-11/12 ml-6" onSubmit={(e) => { e.preventDefault(); fetchAnswer(); }}>
                    <input
                        className="flex h-10 w-full rounded-xl border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 flex-1"
                        type="text"
                        value={question}
                        onChange={handleQuestionChange}
                        placeholder="Ask Leap about"
                        disabled={isLoading}
                    />
                    <button
                        className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
                        type="submit"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Thinking...' : 'Send'}
                    </button>
                </form>
            </div>
        </div>
    );
};

const ConfirmReportModal = ({ onConfirm, onCancel, fetchedQuestion, setFetchedQuestion, isLoading }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex items-center justify-center p-4">
            <div className="bg-white rounded-2xl shadow-xl p-6 max-w-sm w-full">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Report Issue</h3>
                {isLoading ? (
                    <p className="text-gray-600">Fetching question...</p>
                ) : (
                    <><div className='flex justify-center'>
                        <textarea
                            className="w-[330px] h-[200px] p-2 border rounded-xl text-center"
                            value={fetchedQuestion}
                            onChange={(e) => setFetchedQuestion(e.target.value)}
                        />
                        </div>
                        <div className="flex justify-end gap-4 mt-6">
                            <button
                                onClick={onCancel}
                                className="py-2 px-4 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
                                disabled={isLoading}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={onConfirm}
                                className="py-2 px-4 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Reporting question...' : 'Yes, Report'}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const ReportButton = ({ messages, reportChat, confirmReport, isLoading, sessionId,userID }) => {
    const [showModal, setShowModal] = useState(false);
    const [fetchedQuestion, setFetchedQuestion] = useState('');

    const handleReportChat = async () => {
        setShowModal(true);
        await reportChat(setFetchedQuestion);
    };

    const handleConfirm = () => {
        const lastBotMessage = messages.slice().reverse().find(msg => msg.sender === 'bot');
        if (!lastBotMessage) {
            console.error('No bot message found to report.');
            setShowModal(false);
            return;
        }

        const reportData = {
            question: fetchedQuestion,
            session_id: sessionId,
            chat_answer: lastBotMessage.text,
            user_id: userID
        };

        confirmReport(reportData);
    };

    const handleCancel = () => {
        setShowModal(false);
        window.location.reload();
    };

    const isReportAllowed = messages.some(msg => msg.sender === 'bot' && msg.text !== "Hello! I'm the Chatbot, an AI assistant created by LeapCrew AI. How can I help you today?");

    return (
        <>
            <button
                onClick={handleReportChat}
                className="hover:bg-red-500 bg-red-400 backdrop-blur-md text-white font-bold py-2 px-4 rounded inline-flex items-center gap-2"
                disabled={!isReportAllowed || isLoading}
            >
                <Flag/>
            </button>
            {showModal && (
                <ConfirmReportModal
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                    fetchedQuestion={fetchedQuestion}
                    setFetchedQuestion={setFetchedQuestion}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};

export default ChatBot;
