import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageCircle, ClipboardList, Users, Settings2, Ticket, LayoutDashboard, Files } from 'lucide-react';
import DataTable from './Files';
import ChatBot from './Chatbot';
import DashView from './DashView';
import Tickets from './Tickets';
import Task from './Task';
import { useUser, UserProfile, OrganizationProfile, useOrganization, SignOutButton } from "@clerk/clerk-react";
import SentimentComponent from './Sentiment';

const App = () => {
  const [activeMenu, setActiveMenu] = useState('Dashboard');
  const { user } = useUser();
  const { organization } = useOrganization();
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);
  const [showSentiment, setShowSentiment] = useState(true);

  console.log(organization);

  // useEffect(() => {
  //   if (!organization?.id) {
  //     navigate('/SignupForm');
  //   }
  // }, [organization, navigate]);

  const getMenuLinkClass = (menuName) => {
    return `flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform rounded-md ${
      activeMenu === menuName ? 'bg-gray-100 dark:bg-gray-800 dark:text-gray-200' : 'hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200'
    }`;
  };

  const handleFeedbackReceived = () => {
    setShowSentiment(false);
  };

  return (
    <div className="flex h-screen w-full bg-gray-50 dark:bg-gray-900 text-gray-700 dark:text-gray-200">
      <aside className="flex flex-col w-64 h-screen px-4 py-8 overflow-y-auto bg-white border-r rtl:border-r-0 rtl:border-l dark:bg-gray-900 dark:border-gray-700 sticky top-0">
        <a href="/Home">
          <div className="text-black text-xl font-semibold">
            LeapCrew AI
          </div>
        </a>

        <div className="flex flex-col justify-between flex-1 mt-6 relative">
          <nav>
            <a className={getMenuLinkClass('Dashboard')} onClick={() => setActiveMenu('Dashboard')} href="#">
              <LayoutDashboard />
              <span className="mx-4 font-medium">Dashboard</span>
            </a>

            <a className={getMenuLinkClass('Chat')} onClick={() => setActiveMenu('Chat')} href="#">
              <MessageCircle />
              <span className="mx-4 font-medium">Chat</span>
            </a>

            <a className={getMenuLinkClass('Team')} onClick={() => setActiveMenu('Team')} href="#">
              <Users />
              <span className="mx-4 font-medium">Team</span>
            </a>

            <a className={getMenuLinkClass('Tasks')} onClick={() => setActiveMenu('Tasks')} href="#">
              <ClipboardList />
              <span className="mx-4 font-medium">Tasks</span>
            </a>

            <a className={getMenuLinkClass('Documents')} onClick={() => setActiveMenu('Documents')} href="#">
              <Files />
              <span className="mx-4 font-medium">Documents</span>
            </a>

            <hr className="my-6 border-gray-200 dark:border-gray-600" />

            <a className={getMenuLinkClass('Tickets')} onClick={() => setActiveMenu('Tickets')} href="#">
              <Ticket />
              <span className="mx-4 font-medium">Tickets</span>
            </a>

            <a className={getMenuLinkClass('Settings')} onClick={() => setActiveMenu('Settings')} href="#">
              <Settings2 />
              <span className="mx-4 font-medium">Settings</span>
            </a>
          </nav>

          <div
            className="flex items-center px-4 -mx-2 mt-6"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img className="object-cover mx-2 rounded-full h-9 w-9" src={user.imageUrl} alt="avatar"></img>
            {isHovered ? (
              <div className="mx-2">
                <SignOutButton className="px-4 py-2 bg-red-300 text-white rounded-xl hover:bg-red-500 transition-colors duration-300" />
              </div>
            ) : (
              <span className="mx-2 font-medium text-gray-800 dark:text-gray-200">{user.fullName}</span>
            )}
          </div>
        </div>
      </aside>
      <div className="flex flex-1 flex-col overflow-y-auto">
        <header className="flex items-center justify-between bg-white dark:bg-gray-800 p-4"></header>
        <main className="flex-1 p-4">
          {activeMenu === 'Dashboard' && <DashView userId={user.firstName} />}
          {activeMenu === 'Documents' && <DataTable />}
          {activeMenu === 'Chat' && <ChatBot userId={user.id} />}
          {activeMenu === 'Tasks' && <Task/>}
          {activeMenu === 'Team' && <DataTable />}
          {activeMenu === 'Tickets' && <Tickets />}
          {activeMenu === 'Settings' && <Card />}
          {showSentiment && (
            <div className="mt-6 absolute top-4 right-4">
              <SentimentComponent onFeedbackReceived={handleFeedbackReceived} />
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

const Card = () => {
  const [selectedProfile, setSelectedProfile] = useState('User');

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 relative backdrop-blur-lg bg-opacity-30">
      <div className="flex justify-center mb-6">
        <div className="relative inline-flex rounded-full p-1 bg-opacity-20 bg-white backdrop-blur-md shadow-inner">
          <button
            className={`px-6 py-2 text-md font-medium rounded-full transition-colors duration-300 ${
              selectedProfile === 'User' ? 'bg-white bg-opacity-80 shadow-md text-blue-500' : 'text-gray-700 dark:text-gray-300'
            }`}
            onClick={() => setSelectedProfile('User')}
          >
            User
          </button>
          <button
            className={`px-6 py-2 text-md font-medium rounded-full transition-colors duration-300 ${
              selectedProfile === 'Organization' ? 'bg-white bg-opacity-80 shadow-md text-blue-500' : 'text-gray-700 dark:text-gray-300'
            }`}
            onClick={() => setSelectedProfile('Organization')}
          >
            Organization
          </button>
        </div>
      </div>
      <div className="flex justify-center items-center">
        {selectedProfile === 'User' && <UserProfile />}
        {selectedProfile === 'Organization' && <OrganizationProfile />}
      </div>
    </div>
  );
};

export default App;
