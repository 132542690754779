import React from "react";
import Dashboard from "../Component/Dashboard";
import { SignedOut, SignIn, SignedIn } from "@clerk/clerk-react";

const Dasboard = () => {
  return (
    <div>
      <SignedOut>
        <div className="flex items-center justify-center h-screen">
          <SignIn redirectUrl="/Dashboard" />
        </div>
      </SignedOut>
      <SignedIn>
        <header>
          <Dashboard />
        </header>
      </SignedIn>
    </div>
  );
};

export default Dasboard;
