import React, { useState, useEffect } from 'react';

const Progress = () => {
    const [total, setTotal] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [status, setStatus] = useState('Fetching Files');

    useEffect(() => {
        const eventSource = new EventSource('https://leap.up.railway.app/events');

        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setTotal(data.total);
            setCompleted(data.completed);

            if (data.completed < data.total / 2) {
                setStatus('Fetching Files');
            } else if (data.completed < data.total) {
                setStatus('Processing Files');
            } else if (data.completed === data.total) {
                setStatus('Training Completed');
                eventSource.close();
            }
        };

        return () => {
            eventSource.close();
        };
    }, []);

    return (
        <div className="w-full text-center py-4">
            {status !== 'Training Completed' && (
                <>
                    <div className="mb-2 text-lg text-gray-700">{status}</div>
                    <div className="w-full h-8 bg-gray-100 rounded-xl overflow-hidden shadow-md">
                        <div
                            className="h-full bg-blue-600 text-white text-sm flex items-center justify-center rounded-xl transition-all duration-300 ease-in-out"
                            style={{ width: `${(completed / total) * 100}%` }}
                        >
                            {completed} / {total}
                        </div>
                    </div>
                </>
            )}
            {status === 'Training Completed' && (
                <div className="text-lg text-green-700">{status}</div>
            )}
        </div>
    );
};

export default Progress;
