import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, Legend } from 'recharts';
import { PieChart, Pie, Cell, Tooltip as PieTooltip } from 'recharts';
import ActivityComponent from './Activity';

const COLORS = ['#00C49F', '#FF8042', '#0088FE', '#FFBB28', '#FF4444'];

const DashView = (userId) => {
  const [sentimentData, setSentimentData] = useState([]);
  const [overallRating, setOverallRating] = useState(0);

  const [sessionsData, setSessionsData] = useState([]);
  const [messagesData, setMessagesData] = useState([]);
  const [ticketsData, setTicketsData] = useState([]);
  const [timeRange, setTimeRange] = useState('7days');

  useEffect(() => {
    fetch('https://leap.up.railway.app/get-sentiment')
      .then(response => response.json())
      .then(data => {
        const formattedData = Object.keys(data.sentimentCounts).map(key => ({
          name: key,
          value: data.sentimentCounts[key],
        }));
        setSentimentData(formattedData);
        setOverallRating(data.overallRating);
      })
      .catch(error => console.error('Error fetching sentiment data:', error));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [sessions, messages, tickets] = await Promise.all([
          fetch(`https://leap.up.railway.app/get-sessions?type=${timeRange}`).then(res => res.json()),
          fetch(`https://leap.up.railway.app/get-messages?type=${timeRange}`).then(res => res.json()),
          fetch(`https://leap.up.railway.app/get-tickets-data?type=${timeRange}`).then(res => res.json()),
        ]);
  
        console.log('Sessions Data:', sessions.data);
        console.log('Messages Data:', messages.data);
        console.log('Tickets Data:', tickets.data);
  
        setSessionsData(sessions.data || []);
        setMessagesData(messages.data || []);
        setTicketsData(tickets.data || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [timeRange]);
  
  

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen flex flex-col items-center">
      <div className="flex justify-between mb-4 w-full max-w-7xl">
        <span>Time Range</span>
        <select className="border rounded px-2 py-1 bg-transparent" onChange={handleTimeRangeChange}>
          <option value="7days">Last 7 Days</option>
          <option value="24hr">Last 24 Hours</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-7xl">
        <div className="bg-white/30 backdrop-blur-md p-6 rounded-3xl shadow-lg">
          <h2 className="text-xl font-semibold mb-4">Sessions</h2>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={sessionsData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area type="monotone" dataKey="count" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white/30 backdrop-blur-md p-6 rounded-3xl shadow-lg">
          <h2 className="text-xl font-semibold mb-4">Messages</h2>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={messagesData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area type="monotone" dataKey="count" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white/30 backdrop-blur-md p-6 rounded-3xl shadow-lg">
          <h2 className="text-xl font-semibold mb-4">Tickets</h2>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={ticketsData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area type="monotone" dataKey="count" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white/30 backdrop-blur-md p-6 rounded-3xl shadow-lg">
          <h2 className="text-xl font-semibold mb-4">Sentiment Rate</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie data={sentimentData} dataKey="value" cx="50%" cy="50%" outerRadius={80} fill="#8884d8" label>
                {sentimentData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <PieTooltip />
            </PieChart>
          </ResponsiveContainer>
          <div className="text-center mt-4">
            <p className="text-2xl font-semibold">{overallRating.toFixed(1)}</p>
            <p>out of 5</p>
          </div>
        </div>
      </div>
      <div className="bg-white/30 backdrop-blur-md p-6 mt-8 rounded-3xl shadow-lg w-full max-w-7xl">
        <h2 className="text-xl font-semibold mb-4">Most Recent Queries</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-transparent">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-300">Name</th>
                <th className="py-2 px-4 border-b border-gray-300">Query Date</th>
                <th className="py-2 px-4 border-b border-gray-300">Sentiments</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-2 px-4 border-b border-gray-300">Jim</td>
                <td className="py-2 px-4 border-b border-gray-300">01/09/2023</td>
                <td className="py-2 px-4 border-b border-gray-300">80%</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b border-gray-300">Abigail</td>
                <td className="py-2 px-4 border-b border-gray-300">02/09/2023</td>
                <td className="py-2 px-4 border-b border-gray-300">65%</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b border-gray-300">Morgan James</td>
                <td className="py-2 px-4 border-b border-gray-300">05/09/2023</td>
                <td className="py-2 px-4 border-b border-gray-300">40%</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b border-gray-300">Yu Hao</td>
                <td className="py-2 px-4 border-b border-gray-300">06/09/2023</td>
                <td className="py-2 px-4 border-b border-gray-300">32%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ActivityComponent />
    </div>
  );
};

export default DashView;
